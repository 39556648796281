<template>
  <b-card>
    <div>
      <validation-observer ref="provider">
        <b-form>
          <b-row>
            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                label="Quantity"
                label-for="quantity"
              >
                <validation-provider
                  #default="{ errors }"
                  name="quantity"
                  rules="required|integer"
                >
                  <b-form-input
                    v-model.number="form.quantity"
                    type="number"
                    :state="errors.length > 0 ? false : null"
                    placeholder="quantity"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                label="Price"
                label-for="usdtPrice"
              >
                <validation-provider
                  #default="{ errors }"
                  name="usdtPrice"
                  rules="isNumeric|required|min_value:0"
                >
                  <b-form-input
                    v-model="form.usdtPrice"
                    type="number"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Price"
                    @input="val => onInput(val, 'usdtPrice', 4)"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                label="Hold Amount"
                label-for="holdAmount"
              >
                <validation-provider
                  #default="{ errors }"
                  name="holdAmount"
                  rules="isNumeric|required|min_value:0"
                >
                  <b-form-input
                    v-model.trim="form.holdAmount"
                    type="text"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Hold Amount"
                    @input="val => onInput(val, 'holdAmount', 4)"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                label="Gems Quantity"
                label-for="gemsQuantity"
              >
                <validation-provider
                  #default="{ errors }"
                  name="gemQuantity"
                  rules="required|min_value:0|integer"
                >
                  <b-form-input
                    v-model.number="form.gemsQuantity"
                    type="number"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Gems Quantity"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                label="Tasks Quantity"
                label-for="tasksQuantity"
              >
                <validation-provider
                  #default="{ errors }"
                  name="tasksQuantity"
                  rules="required|min_value:0|integer"
                >
                  <b-form-input
                    v-model.number="form.tasksQuantity"
                    type="number"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Tasks Quantity"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <b-button
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            @click.prevent="save"
          >
            Save
          </b-button>
        </b-form>
      </validation-observer>
    </div>
  </b-card>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  BButton, BRow, BCol, BFormGroup, BFormInput, BForm, BCard,
} from 'bootstrap-vue'

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { mapActions } from 'vuex'

import waitRequest from '@/mixins/waitRequest'
import validationError from '@/mixins/validationError'

// eslint-disable-next-line
import { required, min_value, max_value, numeric, isNumeric, integer } from '@validations'
export default {
  name: 'PurchaseEdit',
  components: {
    ToastificationContent,
    ValidationProvider,
    ValidationObserver,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BCard,
  },
  mixins: [waitRequest, validationError],
  data() {
    return {
      itemId: null,
      levelInfo: '',
      form: {
        quantity: 0,
        usdtPrice: '0',
        holdAmount: '0',
        gemsQuantity: 0,
        tasksQuantity: 0,
      },
    }
  },
  mounted() {
    this.initState()
  },
  methods: {
    ...mapActions({
      fetchHoldStoreItem: 'hold/fetchHoldStoreItem',
      updateHoldStoreItem: 'hold/updateHoldStoreItem',
    }),

    initState() {
      this.registerValidator(this.$refs.provider)

      if (!this.$route.params.id) return
      this.itemId = this.$route.params.id
      
      this.fetchHoldStoreItem({ id: this.itemId }).then(response => {
        
        const { price, requirements, ...formData } = response?.data?.item || {}
        const { gems = {}, tasks = {}, hold = {} } = requirements
        
        console.log('data', requirements)
        const usdtPrice = this.$decimal(price?.amount || 0)
          .toDecimalPlaces(4)
          .toNumber()
        const holdAmount = this.$decimal(hold?.amount || 0)
          .toDecimalPlaces(4)
          .toNumber()
        
        this.form = {
          ...formData,
          usdtPrice,
          holdAmount,
          gemsQuantity: gems?.quantity || 0,
          tasksQuantity: tasks?.quantity || 0,
        }
      })
    },

    update() {
      if (this.requestInProgress) return

      const formData = this.form
      let { usdtPrice = '0', holdAmount = '0' } = formData

      const payoutAmount = this.$decimal(usdtPrice || 0)
        .toDecimalPlaces(4)
      holdAmount = this.$decimal(holdAmount || 0)
        .toDecimalPlaces(4)

      this.waitRequest(() => this.updateHoldStoreItem({
        id: this.itemId,
        form: {
          payoutAmount,
          holdAmount,

          quantity: formData.quantity || 0,
          gemQuantity: formData.gemsQuantity || 0,
          tasksQuantity: formData.tasksQuantity || 0,
        },
      })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Item is updated',
              icon: 'PlusIcon',
              variant: 'info',
            },
          })

          this.form = {
            quantity: 0,
            usdtPrice: '0',
            holdAmount: '0',
            gemsQuantity: 0,
            tasksQuantity: 0,
          }

          this.$router.push('/hold-settings')
        })
        .catch(this.checkErrors))
    },

    async save() {
      const isValid = await this.checkIsValidForm()

      if (this.requestInProgress || !isValid) return

      return this.update()
    },

    onInput(val, name, decimal) {
      if (this.isNumber(val)) {
        const amount = this.$decimal(val || 0)
        this.form[name] = amount.todp(decimal).toString()
      } else {
        this.form[name] = val
      }
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';
</style>
