var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('div',[_c('validation-observer',{ref:"provider"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"Quantity","label-for":"quantity"}},[_c('validation-provider',{attrs:{"name":"quantity","rules":"required|integer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number","state":errors.length > 0 ? false : null,"placeholder":"quantity"},model:{value:(_vm.form.quantity),callback:function ($$v) {_vm.$set(_vm.form, "quantity", _vm._n($$v))},expression:"form.quantity"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"Price","label-for":"usdtPrice"}},[_c('validation-provider',{attrs:{"name":"usdtPrice","rules":"isNumeric|required|min_value:0"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number","state":errors.length > 0 ? false : null,"placeholder":"Price"},on:{"input":function (val) { return _vm.onInput(val, 'usdtPrice', 4); }},model:{value:(_vm.form.usdtPrice),callback:function ($$v) {_vm.$set(_vm.form, "usdtPrice", $$v)},expression:"form.usdtPrice"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"Hold Amount","label-for":"holdAmount"}},[_c('validation-provider',{attrs:{"name":"holdAmount","rules":"isNumeric|required|min_value:0"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"text","state":errors.length > 0 ? false : null,"placeholder":"Hold Amount"},on:{"input":function (val) { return _vm.onInput(val, 'holdAmount', 4); }},model:{value:(_vm.form.holdAmount),callback:function ($$v) {_vm.$set(_vm.form, "holdAmount", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.holdAmount"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"Gems Quantity","label-for":"gemsQuantity"}},[_c('validation-provider',{attrs:{"name":"gemQuantity","rules":"required|min_value:0|integer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number","state":errors.length > 0 ? false : null,"placeholder":"Gems Quantity"},model:{value:(_vm.form.gemsQuantity),callback:function ($$v) {_vm.$set(_vm.form, "gemsQuantity", _vm._n($$v))},expression:"form.gemsQuantity"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"Tasks Quantity","label-for":"tasksQuantity"}},[_c('validation-provider',{attrs:{"name":"tasksQuantity","rules":"required|min_value:0|integer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number","state":errors.length > 0 ? false : null,"placeholder":"Tasks Quantity"},model:{value:(_vm.form.tasksQuantity),callback:function ($$v) {_vm.$set(_vm.form, "tasksQuantity", _vm._n($$v))},expression:"form.tasksQuantity"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-button',{staticClass:"mb-1 mb-sm-0 mr-0 mr-sm-1",attrs:{"variant":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.save.apply(null, arguments)}}},[_vm._v(" Save ")])],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }